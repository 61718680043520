<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/partnerList' }" >合伙人列表</el-breadcrumb-item>
          <el-breadcrumb-item>等级管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="primary" @click="levelChange(1)">添加等级</el-button>
      </div>

      <el-table :data="agentLevelData" v-loading="loading">
<!--        <el-table-column prop="p_name" label="创建人" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.p_name == '总后台'" style="color:red">{{scope.row.p_name}}</span>
            <span v-else >{{scope.row.p_name}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="l_name" label="等级名称" align="center"></el-table-column>
        <el-table-column prop="l_percent" label="分成比例" align="center"></el-table-column>
<!--        <el-table-column prop="yspShare" label="线上分成比例" align="center"></el-table-column>
        <el-table-column prop="xxShare" label="线下分成比例" align="center"></el-table-column>
        <el-table-column prop="tjShare" label="推荐分成比例" align="center"></el-table-column>
        <el-table-column prop="addTime" label="创建时间" align="center" width="100"></el-table-column> -->
        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="levelChange(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize" background
          layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="addtype" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form :model="levelAdd" ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入等级名称:">
          <el-input v-model.trim="levelAdd.name" style="width: 260px; float: left" placeholder="请输入等级名称" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入分成:">
          <el-input-number v-model="levelAdd.advPer" style="width: 260px; float: left"  :min="0" :max="100" label="广告分成" :controls="false"></el-input-number>
        </el-form-item>
<!--        <el-form-item label="请输入线上分成:">
          <el-input-number v-model="levelAdd.xsPer" style="width: 260px; float: left" :min="0" :max="100" label="线上分成" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="请输入线下分成:">
          <el-input-number v-model="levelAdd.xxPer" style="width: 260px; float: left" :min="0" :max="100" label="线下分成" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="请输入推荐分成:">
          <el-input-number v-model="levelAdd.tjPer" style="width: 260px; float: left" :min="0" :max="100" label="线下分成" :controls="false"></el-input-number>
        </el-form-item> -->
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="levelAdds">
            <span v-if='addtype == "添加等级"'>添加</span>
            <span v-else>修改</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    partnerLevel,
    partnerLevelAdd, //添加修改 等级
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        agentLevelData: [], // 等级列表数据
        dialogFormVisible: false, // 添加等级弹窗
        loading: false, //加载框
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        levelAdd: {
          id :  '',
          name: '',
          advPer: '',
          xsPer: '',
          xxPer: '',
          tjPer: '',
        }, // 添加修改 等级
        addtype: '' //判断新增 修改
      }
    },
    mounted() {
      this.agentLevelList(1) // 获取等级列表
    },
    methods: {
      agentLevelList(val) { //等级列表
        this.loading = true
        const data = {

        }
        data["page"] = val //	当前页数
        data["limit"] = this.pagesize // 每页条数
        partnerLevel(data).then(res => {
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.agentLevelData = res.data.list // 等级列表
          this.currpage = val //分页显示当前页
          this.loading = false

        }).catch(err => {
          console.log(err)
        })
      },
      levelChange(obj) { // 点击添加等级按钮
        if (obj == 1) {
          this.addtype = "添加等级"
          this.levelAdd = {
            id     : '',
            advPer : 0,  //广告分成
            xsPer  : 0,  //线上分成
            xxPer  : 0,  //线下分成
            tjPer  : 0   //推荐分成
          }
        } else {
          this.addtype = "编辑等级"
          this.levelAdd.id     = obj.id
          this.levelAdd.name   = obj.l_name
          this.levelAdd.advPer = obj.l_percent  //广告分成
          // this.levelAdd.xsPer  = obj.yspShare  //线上分成
          // this.levelAdd.xxPer  = obj.xxShare   //线下分成
          // this.levelAdd.tjPer  = obj.tjShare   //推荐分成
        }
        this.dialogFormVisible = true;
      },
      levelAdds() { // 点击添加等级按钮

      if(this.levelAdd.name == "" ){
        this.$message.error('请填写等级名称');
        return
      }else{
        const data = {
          type          : this.levelAdd.id == "" ? 1 : 2,
          l_name        : this.levelAdd.name,
          l_percent     : this.levelAdd.advPer,
          // yspShare      : this.levelAdd.xsPer,
          // xxShare       : this.levelAdd.xxPer,
          // tjShare       : this.levelAdd.tjPer,
          id            : this.levelAdd.id
        }
        partnerLevelAdd(data).then(res => {
          if(res.status.code == 1){
            this.$message.success('操作成功');
            this.agentLevelList(this.currpage) // 获取等级列表
            this.dialogFormVisible = false;
          }else{
            this.$message.error(res.status.msg);
          }
        }).catch(err => {
          console.log(err)
        })
      }



      },
      handleCurrentChange(val) { // 分页
        this.agentLevelList(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;

    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
      color: #000000;
      margin-left: 32px;
    }

    .btn {
      display: flex;
      align-items: center;
      width: 125px;
      height: 32px;
      opacity: 1;
      border-radius: 2px;
    }
  }

  .query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
      width: 80%;
      display: flex;

      div {
        width: 22%;
        text-align: left;

        .input,
        .select {
          width: 60%;
        }
      }
    }
  }

  .iconClick {
    color: #409EFF;
    margin-left: 3px;
    cursor: pointer;
  }
</style>
